export default class ApiError extends Error {
  constructor(axiosError) {
    super()
    this.axiosError = axiosError
  }

  get status() { return this.axiosError.response.status }

  get message() {
    return this.axiosError.response.statusText
  }

  get field() {
    return this.axiosError.response.field || null
  }
}