import axios from 'axios'
import { Methods } from '@/libraries/request/'

export default class Api {
  static call(request) {
    request.cancelSource = axios.CancelToken.source()

    const defaultHeaders = {
      Accept: 'application/json',
      'Content-Type': 'application/json; charset=utf-8',
    }

    const options = {
      method: request.method,
      baseURL: request.baseUrl,
      url: request.path,
      headers: {
        ...defaultHeaders,
        ...request.headers,
      },
      cancelToken: request.cancelSource.token,
      timeout: 5000,
      params: {
        time_stamp: Date.now() //MEMO: IE11のキャッシュ対策
      }
    }

    if (options.method === Methods.get) {
      Object.assign(options.params, request.parameters)
    } else {
      options.data = request.parameters
    }

    return new Promise((resolve, reject) => {
      axios(options)
        .then((response) => {
          try {
            resolve(request.decode(response))
          } catch (error) {
            reject(request.decodeError(error))
          }
        })
        .catch((error) => {
          if (axios.isCancel(error)) {
            return
          }
          reject(request.decodeError(error))
        })
    })
  }

  static cancel(request) {
    request.cancelSource.cancel()
  }
}